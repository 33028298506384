import styled from "styled-components";
import Modal from "styled-react-modal";

import logo from "../assets/logo.png";

export const StyledModal = Modal.styled`
  width: 25rem;
  height: 25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #282c34;
  border-radius: 12px;
  color: white;
`;

export const Header = styled.header`
  background-color: #282c34;
  min-height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;
`;

export const Logo = styled.img.attrs({
  src: logo,
})`
  padding-left: 15px;
`;

export const Body = styled.div`
  background-color: #282c34;
  color: white;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: calc(10px + 2vmin);
  justify-content: space-around;
  min-height: calc(50vh);
  width: 100%;
  font-family: "Days One", sans-serif;
`;

export const CardSection = styled.div`
  background-color: #41444b;
  height: 30rem;
  width: 49%;
  border-radius: 12px;
`;

export const CardSectionDown = styled.div`
  background-color: #41444b;
  height: 26rem;
  width: 99%;
  border-radius: 12px;
  overflow: auto;
`;

export const CardTitle = styled.h4`
  padding: 0px;
  margin: 10px;
`;

export const Table = styled.table`
  width: 100%;
  background-color: #565a63;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 16px;
  margin-top: 20px;
  border-collapse: collapse;
`;

export const TableBody = styled.tbody`
  width: 100%;
  background-color: #565a63;
`;

export const TableRow = styled.tr`
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 2.5px solid #41444b;
`;

export const TableCell = styled.td`
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 10px;
`;

export const Link = styled.a`
  color: #448fff;
  text-decoration: none;
  cursor: pointer;
`;

export const Input = styled.input`
  padding: 8px;
  font-size: 16px;
  border-width: 1px;
  border-color: #cccccc;
  background-color: #ffffff;
  color: #000000;
  border-style: solid;
  border-radius: 8px;
  box-shadow: 0px 0px 5px rgba(66, 66, 66, 0.75);
  text-shadow: 0px 0px 5px rgba(66, 66, 66, 0.75);
`;

export const Image = styled.img`
  height: 40vmin;
  margin-bottom: 16px;
  pointer-events: none;
`;

export const Button = styled.button`
  background-color: ${(props) => (props.primary ? "#63a7ff" : "#b3b3b3")};

  border: none;
  border-radius: 8px;
  color: white;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  margin: 0px 20px;
  padding: 12px 24px;

  ${(props) => props.hidden && "hidden"} :focus {
    border: none;
    outline: none;
  }
`;
