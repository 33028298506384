const config = {
  apiKey: "AIzaSyCBtmNF7yAnO_n-u7CPKAl99cwCTWV4rHo",
  authDomain: "meshswap-fe19f.firebaseapp.com",
  databaseURL: "https://meshswap-fe19f-default-rtdb.firebaseio.com",
  projectId: "meshswap-fe19f",
  storageBucket: "meshswap-fe19f.appspot.com",
  messagingSenderId: "382707273586",
  appId: "1:382707273586:web:41395a33612755e0b8d7eb",
};

export default config;
