import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { useSelector, useDispatch } from "react-redux";
import { ModalProvider } from "styled-react-modal";
import { ethers } from "ethers";
import firebase from "firebase/compat/app";
import { child, get, getDatabase, push, ref, update } from "firebase/database";

import { Contract } from "@ethersproject/contracts";

import { setAccount as _setAccount } from "./redux/account";
import config from "./fb/config";

import {
  Body,
  Button,
  CardSection,
  CardSectionDown,
  CardTitle,
  Header,
  Input,
  Link,
  Logo,
  StyledModal,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "./components";
import useWeb3Modal from "./hooks/useWeb3Modal";

import { addresses, abis } from "@project/contracts";
import GET_TRANSFERS from "./graphql/subgraph";

function WalletButton({ provider, loadWeb3Modal, logoutOfWeb3Modal }) {
  const dispatch = useDispatch();

  const [account, setAccount] = useState("");
  const [rendered, setRendered] = useState("");

  useEffect(() => {
    async function fetchAccount() {
      try {
        if (!provider) {
          return;
        }

        // Load the user's accounts.
        const accounts = await provider.listAccounts();
        setAccount(accounts[0]);
        dispatch(_setAccount(accounts[0]));

        // Resolve the ENS name for the first account.
        const name = await provider.lookupAddress(accounts[0]);

        // Render either the ENS name or the shortened account address.
        if (name) {
          setRendered(name);
        } else {
          setRendered(account.substring(0, 6) + "..." + account.substring(36));
        }
      } catch (err) {
        setAccount("");
        dispatch(_setAccount(""));
        setRendered("");
        console.error(err);
      }
    }
    fetchAccount();
  }, [account, provider, setAccount, setRendered]);

  return (
    <Button
      onClick={() => {
        if (!provider) {
          loadWeb3Modal();
        } else {
          logoutOfWeb3Modal();
        }
      }}
    >
      {rendered === "" && "Connect Wallet"}
      {rendered !== "" && rendered}
    </Button>
  );
}

function App() {
  const account = useSelector((state) => state.account.address);
  const { loading, error, data } = useQuery(GET_TRANSFERS);
  const [provider, loadWeb3Modal, logoutOfWeb3Modal] = useWeb3Modal();
  const [userNfts, setNfts] = useState([]);
  const [listedNfts, setListedNfts] = useState([]);
  const [selected, setSelected] = useState({});
  const [price, setPrice] = useState(0);

  const [isOpen, setIsOpen] = useState(false);

  function toggleModal(item) {
    setSelected(item);
    setIsOpen(!isOpen);
  }

  React.useEffect(() => {
    if (!loading && !error && data && data.transfers) {
      getUserNFTs(account);
    }
  }, [loading, error, data]);

  React.useEffect(() => {
    firebase.initializeApp(config);

    const dbRef = ref(getDatabase());
    get(child(dbRef, `nfts`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          console.log(snapshot.val());
          const values = snapshot.val();
          const l = [];
          Object.keys(values).map((k) => {
            l.push(values[k]);
          });
          setListedNfts(l);
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  async function getUserNFTs(account) {
    const url = `https://api.opensea.io/api/v1/assets?owner=${account}`;
    const proxy = await fetch(url);
    const resp = await proxy.json();

    setNfts(resp.assets);
  }

  async function list(contractAddress, nftID) {
    // Should replace with the end-user wallet, e.g. Metamask
    // const defaultProvider =  new ethers.providers.Web3Provider(window.ethereum)
    // Create an instance of an ethers.js Contract
    // Read more about ethers.js on https://docs.ethers.io/v5/api/contract/contract/
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();

    const contract = new Contract(addresses.swap, abis.swap, signer);
    const filter = contract.filters.NFTListed(account);
    const _price = ethers.utils.parseEther(price);
    contract.on(filter, function(owner, _nftID) {
      const db = getDatabase();
      const data = {
        nftID: _nftID.toString(),
        owner,
        nft: nftID,
        nftContract: contractAddress,
        price,
        link: selected?.permalink,
      };

      const newTokenKey = push(child(ref(db), "listed/")).key;

      // Write the new post's data simultaneously in the posts list and the user's post list.
      const updates = {};
      updates["/nfts/" + newTokenKey] = data;

      contract.removeAllListeners();
      return update(ref(db), updates);
    });

    const tx = await contract.list(contractAddress, nftID, _price);
    await tx.wait();
    console.log(tx);
  }

  console.log(listedNfts);

  return (
    <ModalProvider>
      <div>
        <Header>
          <Logo width="20" alt="logo" />
          <WalletButton
            provider={provider}
            loadWeb3Modal={loadWeb3Modal}
            logoutOfWeb3Modal={logoutOfWeb3Modal}
          />
        </Header>
        <Body>
          <CardSection>
            <CardTitle>My NFTs</CardTitle>
            <Table>
              <TableBody>
                {userNfts.map((i, idx) => (
                  <TableRow key={idx}>
                    <TableCell style={{ width: "60%" }}>
                      #{i.token_id.substr(0, 10)}
                    </TableCell>
                    <TableCell style={{ width: "20%" }}>
                      <Link href={i.permalink} target="_blank">
                        View in Opensea
                      </Link>
                    </TableCell>
                    <TableCell style={{ width: "20%" }}>
                      <Link href="#" onClick={() => toggleModal(i)}>
                        List
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardSection>
          <CardSection>
            <CardTitle>My Proposals</CardTitle>
          </CardSection>
        </Body>
        <Body>
          <CardSectionDown>
            <CardTitle>Listed NFTs</CardTitle>
            <Table>
              <TableBody>
                {listedNfts.map((i, idx) => (
                  <TableRow key={idx}>
                    <TableCell style={{ width: "60%" }}>
                      #{i.nft.substr(0, 10)}
                    </TableCell>
                    <TableCell style={{ width: "10%" }}>
                      {i.price?.toLocaleString(undefined, {
                        minimumFractionDigits: 4,
                        maximumFractionDigits: 4,
                      })}{" "}
                      ETH
                    </TableCell>
                    <TableCell style={{ width: "10%" }}>
                      <Link href={i.link} target="_blank">
                        View in Opensea
                      </Link>
                    </TableCell>
                    <TableCell style={{ width: "20%" }}>
                      <Link href="#" onClick={() => toggleModal(i)}>
                        Make offer
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardSectionDown>
        </Body>
      </div>
      <StyledModal
        isOpen={isOpen}
        onBackgroundClick={toggleModal}
        onEscapeKeydown={toggleModal}
      >
        <CardTitle style={{ fontFamily: `"Days One", sans-serif` }}>
          List your NFT
        </CardTitle>
        <span>
          NFT Contract address:{" "}
          <Link
            href={`https://etherscan.io/address/${selected?.asset_contract?.address}`}
            target="_blank"
          >
            {selected?.asset_contract?.address?.substr(0, 5)}...
            {selected?.asset_contract?.address?.substr(
              selected?.asset_contract?.address?.length - 5
            )}
          </Link>
        </span>
        <span>
          NFT ID:{" "}
          <Link href={selected?.permalink} target="_blank">
            #{selected?.token_id?.substr(0, 10)}
          </Link>
        </span>
        <p>Price:</p>
        <Input onChange={(e) => setPrice(e.target.value)} type="number" />
        <br />
        <p>
          <Button onClick={toggleModal}>Close</Button>
          &nbsp;
          <Button
            onClick={() =>
              list(selected.asset_contract.address, selected?.token_id)
            }
            primary
          >
            List
          </Button>
        </p>
      </StyledModal>
    </ModalProvider>
  );
}

export default App;
