import { createSlice } from "@reduxjs/toolkit";

export const accountSlice = createSlice({
  name: "accountReducer",
  initialState: {
    address: "0x0",
  },
  reducers: {
    setAccount: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.address = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAccount } = accountSlice.actions;

export default accountSlice.reducer;
